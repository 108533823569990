import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 979.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,979.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M0 4895 l0 -4895 5400 0 5400 0 0 4895 0 4895 -5400 0 -5400 0 0
-4895z m7750 1595 l-1 -740 -42 -20 c-23 -10 -78 -33 -122 -50 -44 -18 -84
-35 -90 -39 -5 -4 -32 -16 -60 -26 -46 -17 -95 -37 -203 -86 -23 -10 -47 -19
-53 -19 -6 0 -34 -11 -62 -25 -80 -37 -418 -179 -697 -292 -36 -15 -69 -30
-75 -34 -5 -4 -48 -22 -95 -40 -47 -17 -86 -36 -88 -41 -2 -4 -10 -8 -17 -8
-12 0 -169 -63 -260 -105 -17 -8 -66 -28 -110 -46 -44 -17 -84 -35 -90 -39 -5
-4 -21 -11 -35 -15 -14 -4 -47 -17 -75 -30 -54 -23 -99 -42 -240 -100 -49 -21
-103 -43 -120 -51 -16 -7 -52 -22 -80 -32 -27 -11 -75 -31 -105 -45 -30 -13
-86 -37 -125 -52 -38 -15 -74 -30 -80 -34 -5 -4 -48 -22 -95 -40 -47 -18 -103
-42 -125 -52 -22 -10 -101 -44 -175 -75 -74 -31 -148 -62 -165 -69 -84 -37
-181 -75 -192 -75 -7 0 -13 -4 -13 -10 0 -5 -6 -10 -12 -10 -7 0 -51 -16 -98
-36 -47 -20 -110 -46 -140 -58 -30 -13 -75 -32 -100 -44 -61 -28 -238 -102
-277 -116 -18 -6 -33 -14 -33 -18 0 -10 84 -48 104 -48 9 0 16 -4 16 -9 0 -5
15 -14 33 -21 45 -18 82 -34 157 -68 36 -16 84 -37 107 -47 23 -10 40 -21 38
-24 -1 -3 -276 -5 -609 -3 l-606 2 0 669 0 669 77 32 c43 17 101 42 128 55 28
13 86 38 130 56 44 17 85 36 91 41 6 4 35 17 65 27 30 10 89 35 131 55 42 20
79 36 82 36 3 0 29 11 58 24 29 14 80 36 113 49 33 14 98 42 145 62 47 20 90
38 95 39 6 2 37 15 70 30 33 16 74 34 90 41 17 7 46 20 65 28 19 9 64 28 100
42 36 15 89 38 118 51 29 13 55 24 57 24 3 0 33 13 67 29 35 16 92 41 128 56
36 15 79 33 95 40 17 7 64 27 105 45 41 18 86 38 100 45 14 8 44 20 65 28 41
15 364 154 467 201 31 14 60 26 63 26 6 0 75 30 161 71 23 10 44 19 47 19 4 0
30 11 59 24 29 13 94 41 143 61 50 21 118 51 153 66 34 16 65 29 68 29 3 0 29
11 57 24 103 48 164 74 267 116 58 24 137 57 175 75 39 18 115 52 170 75 55
23 114 48 130 55 17 8 64 28 105 45 41 17 89 38 105 45 17 8 116 51 220 95
105 45 204 88 222 96 17 8 34 14 37 14 3 0 34 13 69 30 76 36 199 88 210 89 4
1 7 -332 7 -739z m355 -939 c11 -16 51 -76 89 -133 38 -57 77 -115 87 -130 11
-15 19 -30 19 -32 0 -3 16 -30 35 -60 40 -63 131 -243 170 -336 15 -36 33 -78
40 -95 7 -16 25 -66 38 -110 160 -516 144 -964 -49 -1383 -19 -41 -34 -75 -34
-77 0 -11 -123 -203 -172 -269 -57 -75 -223 -248 -293 -307 -276 -228 -579
-380 -940 -474 -422 -109 -847 -112 -1277 -9 -109 26 -127 37 -85 49 24 8 203
84 283 121 28 13 58 24 67 24 10 0 17 5 17 10 0 6 4 10 9 10 5 0 97 38 203 85
l194 84 144 10 c316 22 545 83 780 208 115 61 236 138 248 157 4 7 16 17 26
23 24 13 206 194 206 204 0 5 8 16 18 26 60 59 176 262 217 378 9 28 21 59 26
70 18 40 49 182 60 274 7 57 9 162 6 265 -6 184 -25 298 -74 436 -14 41 -32
93 -40 115 -19 54 -104 219 -114 223 -5 2 -9 9 -9 16 0 17 -137 226 -148 226
-5 0 -14 10 -20 23 -5 12 -47 64 -93 115 -64 73 -79 96 -68 103 8 5 17 9 21 9
4 0 90 40 190 90 101 49 188 90 193 90 6 0 19 -13 30 -29z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
